import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import store from './store/index'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import 'element-ui/lib/theme-chalk/display.css';
import './assets/css/comm.css'
import axios from "axios";
import { Message } from "element-ui";
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import wxlogin from 'vue-wxlogin';
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraRTM from "agora-rtm-sdk";
import isMobile from './utils/isMobile'
//import 'video.js/dist/video-js.css'引入videojs样式，后期不需要可删除——20210714
import { TcPlayer } from '../src/assets/js/TcPlayer-module-2.4.1'//引入TcPlayer，后期不需要可删除——20210714
Vue.prototype.TcPlayer = TcPlayer
Vue.prototype.$isMobile = isMobile
Date.prototype.Format = function (fmt) { // author: meizz
  var o = {
    "M+": this.getMonth() + 1, // 月份
    "d+": this.getDate(), // 日
    "h+": this.getHours(), // 小时
    "m+": this.getMinutes(), // 分
    "s+": this.getSeconds(), // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    "S": this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
}
//然后再修改原型链
// Vue.prototype.$axios = axios
Vue.component('my-component', {
  components: {
    wxlogin
  }
});
Vue.prototype.globalVerb = {
  openMpOpenPlatformHost: "http://afopen.afarsoft.com"
}

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue()
Vue.use(ElementUI, {locale})
Vue.prototype.$message = Message;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
