import myajax from "./myajax"
export const login=(user)=>myajax('/blade/bloodvideo/login',user,'POST')//登录
export const getmess=(phone)=>myajax('/blade/bloodvideo/sendMessage?phone='+phone,{},'POST')//发送验证码
export const getlist=()=>myajax('/blade/bloodvideo/list')//获取列表
export const getdetail=(id)=>myajax('/blade/bloodvideo/detail?id='+id)
export const bindsubmit=(userinfo)=>myajax('/blade-blood/doctor/submit',userinfo,'POST')//绑定
export const getdoclist=(data)=>myajax('/blade-blood/doctor/list?openid='+data)
export const getvideoList=(data)=>myajax('/blade/bloodlive/list',data,'GET')//获取指定主播信息
export const getvideoallList=()=>myajax('/blade/bloodlive/list')//获取所有直播列表
export const editHostInfo=(data)=>myajax('/blade/bloodlive/submit',data,"POST")//编辑直播信息
export const searchDoc=(data)=>myajax('/blade-blood/doctor/list',data,"GET")//电话搜索
export const getRtcToken=(data)=>myajax('/blade/agoraToken/getRtcToken',data,"POST")//rtc获取token——1为主播，2为观众
export const getRtmToken=(data)=>myajax('/blade/agoraToken/getRtmToken',data,"POST")//rtm获取token——1为主播，2为观众
export const selList=(data)=>myajax('/blade/bloodlive/selList',data,"GET")//获取指定直播&主播信息
export const searchPower=(data)=>myajax('/blade/bloodlive/selLivePower',data,"GET")//查询观众权限
export const lazyTree = (id) => myajax('/blood/recruit/lazy-tree?parentId='+id)
export const detail = (id) => myajax('/blood/recruit/detail?id='+id)
export const getMetDetail=(data)=>myajax('/blade/meeting/detail',data,"GET")//查询观众权限
export const wechatShare=(data)=>myajax('/wx/mp/share?url='+data,{},"POST")//微信分享

// /blade/bloodlive/selLivePower?agoraUids=5954174778





