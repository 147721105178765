import axios from "axios";
import {
  mapState
} from "vuex";
import {
  getlist
} from "../../api/index";
import {
  api,
  config
} from "../../utils/urllist"
import preventBack from "vue-prevent-browser-back";
import {wxChatShare} from "../../utils/wechat"
// import infiniteScroll from 'vue-infinite-scroll';
var sUserAgent = navigator.userAgent.toLowerCase();
var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
var bIsMidp = sUserAgent.match(/midp/i) == "midp";
var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
var bIsAndroid = sUserAgent.match(/android/i) == "android";
var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
export default {
  name: "Home",
  components: {},
  // directives: { infiniteScroll },
  data() {
    return {
      isNomore: false,
      busy: false,
      navActive: 0,
      searchtitle: "",
      headerlist: [{
          title: "首页",
          toUrl: "/"
        },
        {
          title: "直播大厅",
          toUrl: "/liveHall"
        },
      ],
      openId: "",
      headImgUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      mixins: [preventBack], //注入
      nickName: "",
      username: "",
      currentPage: 1,
      pagesize: 8,
      datalist: [],
      datalistbyph: [],
      total: 0,
    };
  },
  created() {
    // this.$router.push("/recruitList")
    if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
    } else {
      this.getData(this.currentPage + 1, this.pagesize);
      if (this.datalistbyph.length != 0 && this.datalistbyph.length == this.total) {
        this.isNomore = true
      }
    }
    if (sessionStorage.getItem("userinfo")) {
      this.headImgUrl = JSON.parse(
        sessionStorage.getItem("userinfo")
      ).headImgUrl;
      this.nickName = JSON.parse(sessionStorage.getItem("userinfo")).nickName;
      this.openId = JSON.parse(sessionStorage.getItem("userinfo")).openId;
      this.username = JSON.parse(sessionStorage.getItem("userinfo")).name;
    }
    this.getData(this.currentPage, this.pagesize);
  },
  mounted() {
    //防止页面后退
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      history.pushState(null, null, document.URL);
    });
    var stateObject = {} 
    var title = '' //标题这个可以忽略
    var newUrl = '' //重点是这个，这个参数是存改变过后的url
    if (location.href.indexOf('?') == '-1') {
    newUrl = window.location.href
    } else {
    newUrl = encodeURI(location.href.split('?')[0] + '#' + location.href.split('#')[1])
    }
    history.pushState(stateObject, title, newUrl)
    console.log("首页url：",window.location.href);
    let params = {
      url:window.location.href,
      title:"首页",
      link:(window.location.href).replace(window.location.search,''),
      imgUrl: "https://yilian-biz.oss-cn-shanghai.aliyuncs.com//upload/20211112/03eab30b96b9997a72503b690552fb6e.jpeg",
      desc:"王振义院士疑难病例讨论",
      type:"",
      dataUrl:""
    }
    wxChatShare(params)
    // this.getProductBrand(0, "", 0);
  },
  methods: {
    todetail(data) {
      let routeUrl = this.$router.resolve({
        path: "/videodetail",
        query: {
          id: data,
          type:"videodetail"
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    topage(url, ind) {
      this.navActive = ind
      this.$router.push(url)
    },
    getData(cpage, psize) {
      axios
        .get(
          api.urlHead + api.getvideolist, {
            params: {
              current: cpage,
              size: psize,
            },
          },
          config
        )
        .then((res) => {
          this.datalist = res.data.data.records;
          this.datalistbyph = this.datalistbyph.concat(res.data.data.records);
          this.total = res.data.data.total;
          this.pagesize = res.data.data.size;
          this.currentPage = res.data.data.current;
        })
        .catch((err) => {});
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.getData(this.currentPage, this.pagesize);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData(this.currentPage, this.pagesize);
    },
    loginout() {
      this.$confirm("是否退出登录?", "退出", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          sessionStorage.removeItem("user");
          this.$router.replace("/login"); //$router.replace()--跳转页面，history不添加新纪录
          this.$message({
            type: "success",
            message: "退出成功!",
            duration: 1000,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
            duration: 1000,
          });
        });
    },
    // 移动端检测滚动条加载数据
    videoload() {
      this.busy = true
      setTimeout(() => {
        if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
          // alert("当前是电脑打开")
        } else {
          this.getData(this.currentPage + 1, this.pagesize);
          if (this.datalistbyph.length != 0 && this.datalistbyph.length == this.total) {
            this.isNomore = true
          }
        }
        this.busy = false
      }, 1000)
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem("urlAll")
  }
};