//promise封装数据
import axios from "./myhttp";
export default function myajax(url,data={},type="GET"){
    return new Promise((resolve,reject)=>{
        let promise;
     //obj:{id:1,name:"admin"}
        //localhost:4000/user?id=1&name=amdin 
        if(type==="GET"||type==="DELETE"){
            let dataStr="";
            Object.keys(data).forEach(key=>{
                dataStr+=key+"="+data[key]+"&"
            })
            if(dataStr!==""){
                dataStr = dataStr.substring(0,dataStr.length-1);
                url = url + "?"+dataStr;
            }
            //发送get请求
            if(type==="GET"){
                promise = axios.get(url);
            }else{
                promise = axios.delete(url);
            }
        }else if(type==="POST"){
            promise = axios.post(url,data);
        }else if(type==="PUT"){
            promise = axios.put(url,data);
        }
        promise.then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err);
        })
    })
}