// wetchat.js －－ 个人封装
//import wx from 'weixin-js-sdk'; 引入wxJS
import { wechatShare } from '../api/index';
export async function wxChatShare(param) {
    let _url = encodeURIComponent(param.url.split("#")[0]) //当前页面的url
    let res = await wechatShare(_url)
    console.log("wechat", res, res.data.appId);
    if (res.code == 200) {
        wx.checkJsApi({
            jsApiList: ['chooseImage'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {
                console.log("checkJsApi", res);
                // 以键值对的形式返回，可用的api值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            }
        });
        // 接口返回配置信息
        wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: [ // 用的方法都要加进来
                'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareTimeline', 'onMenuShareAppMessage'
            ]
        });
        wx.ready(function () {
            //分享到朋友圈
            wx.updateTimelineShareData({
                title: param.title, // 分享标题
                link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: param.imgUrl, // 分享图标
                success: function () {
                    // 设置成功
                    console.log("分享到朋友圈成功返回的信息为:", res);
                    this.$Message.message("设置成功!");
                }
            })

            wx.onMenuShareTimeline({
                title: param.title, // 分享标题
                link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: param.imgUrl, // 分享图标
                success: function () {
                    // 用户点击了分享后执行的回调函数
                },
            })

            //分享给朋友

            wx.updateAppMessageShareData({
                title: param.title, // 分享标题
                desc: param.desc, // 分享描述
                link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: param.imgUrl, // 分享图标
                success: function () {
                    // 设置成功
                    console.log("分享到朋友成功返回的信息为:", res);
                    this.$Message.message("设置成功!");
                }
            })
            wx.onMenuShareAppMessage({
                title: param.title, // 分享标题
                desc: param.desc, // 分享描述
                link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: param.imgUrl, // 分享图标
                type: param.type, // 分享类型,music、video或link，不填默认为link
                dataUrl: param.dataUrl, // 如果type是music或video，则要提供数据链接，默认为空
                success: function () {
                    console.log("点击分享成功");
                    // 用户点击了分享后执行的回调函数
                }
            });
        });
        wx.error(function (res) {
            console.log('验证失败返回的信息:', res);
        });
    } else {
        console.log(res.data.message);
    }
}