import Vue from 'vue'
import Vuex from 'vuex'
// import user from './user'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "",
    userinfo: {},
    latest: "",
    openId: "",
    toUrl:""
  },
  mutations: {
    SET_OPENID(state, opid) {
      state.openId = opid
    },
    SET_TOKEN(state, tok) {
      state.token = tok
    },
    SET_USERINFO(state, user) {
      state.userinfo = user
    },
    SET_URL(state, url) {
      state.toUrl = url
    },
    setHasLatest(state, late) {
      state.latest = late
    }

  },
  actions: {
    async SET_TOKEN_ACTION({ commit }, tok) {
      commit("SET_TOKEN", tok)
    },
    async SET_USERINFO_ACTION({ commit }, user) {
      commit("SET_USERINFO", user)
    },
    async setHasLatest_ACTION({ commit }, late) {
      commit("setHasLatest", late)
    },
    async SET_OPENID_ACTION({ commit }, oid) {
      commit("SET_OPENID", oid)
    },
    async SET_URL_ACTION({ commit }, url) {
      commit("SET_URL", url)
    },
  },
  getters: {

  },
  modules: {
    // user
  }
})
