<template>
  <div id="app">
    <my-header v-if="$route.meta.isHeader"></my-header>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
window.onload = function() {
  document.addEventListener('touchstart', function(event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function(event) {
    event.preventDefault()
  })
}
import MyHeader from "./components/header/header.vue"
export default {
  components:{
    MyHeader
  }
}
</script>
<style lang="less">

  .h80 {
    width: 100%;
    height: 80px;
  }

*{
  margin: 0;
  padding:0;
  list-style: none;
  text-decoration: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  // font-size: 12px;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.el-message-box{
  width: auto;
  padding: 1rem;
}
// .el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover{
//   box-shadow: 0.3rem 0.3rem 0.3rem rgb(201 201 231 / 29%);
// }
</style>
