import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import store from "../store/index";
// import store from "../store/user";
import axios from "axios"
import $ from "jquery"
import {
  GetParam
} from "../utils/getParams.js";
import {
  api,
  config
} from "../utils/urllist"
Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: Home,
  meta: {
    isLogin: true,
    isHeader: true,
    content: "width=device-width, initial-scale=1.0,user-scalable=0"
  }
},
{
  path: '/login',
  name: 'Login',
  component: () => import('../views/Login/Login.vue'),
  meta: {
    content: "width=device-width, initial-scale=1.0,user-scalable=0"
  }
},
{
  path: '/register',
  name: 'Register',
  component: () => import('../views/Register/Register.vue')
},
{
  path: '/videodetail',
  name: 'videoDetail',
  component: () => import('../views/videoDetail/videoDetail.vue'),
  meta: {
    isLogin: true,
    isHeader: false,
  }
},
{
  path: '/bindpage',
  name: 'bindpage',
  component: () => import('../views/BindPage/BindPage.vue'),
  meta: {
    isHeader: false,
  }
},
// {
//   path: '/liveHall',
//   name: 'liveHall',
//   component: () => import('../views/liveHall/liveHall.vue'),
//   meta: {
//     isHeader: true,
//     title: '直播大厅',
//   },
// },
{
  path: '/videoTest',
  name: 'videoTest',
  component: () => import('../views/videoTest/videoTest.vue'),
  meta: {
    isHeader: false,
    title: '直播间测试',
    isLogin: true,
  },
},
// {
//   path: '/liveRoom',
//   name: 'liveRoom',
//   component: () => import('../views/liveRoom/liveRoom.vue'),
//   meta: {
//     isHeader: true,
//     title: '直播间',
//   },
// },
// {
//   path: '/liveChannel',
//   name: 'liveChannel',
//   component: () => import('../views/liveChannel/liveChannel.vue'),
//   meta: {
//     isHeader: true,
//     title: '我的直播',
//   },
// },
{
  path: '/SharePage',
  name: 'SharePage',
  component: () => import('../views/SharePage/SharePage.vue'),
  meta: {
    isHeader: true,
    title: '分享',
  },
},
{
  path: '/recruitList',
  name: 'recruitList',
  meta: {
    keepAlive: true,
    isHeader: true,
    title: '临床研究',
  },
  component: () =>
    import('../views/recruit/list.vue')
}, {
  path: '/recruitDetail',
  name: 'recruitDetail',
  meta: {
    isHeader: true,
    title: '临床研究',
  },
  component: () =>
    import('../views/recruit/detail.vue')
},
]
const router = new VueRouter({
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
var ua = navigator.userAgent.toLowerCase();
router.beforeEach((to, from, next) => {
  let sesurl = sessionStorage.getItem("urlAll")
  if (!sesurl) {
    let urlist = {
      path: to.path,
      query: to.query
    }
    let urlAll = []
    urlAll.push(urlist)
    sessionStorage.setItem("urlAll", JSON.stringify(urlAll))
  }
  let isBind  = sessionStorage.getItem("isBind")
  if (!isBind && ua.match(/MicroMessenger/i) == "micromessenger") {
    let appid = "wxf5a6b7ced890abd8"; //实际公众号appid，这里的appid对应的微信应用一定是绑定当前h5项目所在线上域名，也就是需要在微信开放平台绑定js安全域名
    let redirect_uri = encodeURIComponent(`https://s.yi-lian.net/#/bindpage`);
    let url =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
      appid + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
    // 以snsapi_base为scope发起的网页授权，是用来获取进入页面的用户的openid的，并且是静默授权并自动跳转到回调页的.（静默的另一种：对于已关注公众号的用户，如果用户从公众号的会话或者自定义菜单进入本公众号的网页授权页，即使是scope为snsapi_userinfo，也是静默授权，用户无感知。）以snsapi_userinfo为scope发起的网页授权，是用来获取用户的基本信息的。但这种授权需要用户手动同意，并且由于用户同意过，所以无须关注，就可在授权后获取该用户的基本信息。    
    let code = GetParam(window.location.href.split("#")[0], "code");
    if (!code) {
      window.location.href = url;
    } else {
      next()
    }
  } else {
    if (to.meta.isLogin) {
      if (sessionStorage.getItem("userinfo") || sessionStorage.getItem("phone")) {
        next();
      } else {
        router.push("/login")
      }
    } else {
      next();
    }

  }
})
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
export default router