

const api = {
  urlHead: "https://b.yi-lian.net/api",
  loginSubmit: "/blade/bloodvideo/login",
  sendmsm: "/blade-blood/sms/sendMessage",
  smscheck: "/blade-blood/sms/check",
  getdoclist: "/blade-blood/doctor/list",
  getuserinfo: "/wechat/userInfo",
  getvideolist: "/blade/bloodvideo/list"
}
const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
export { api, config}