import  axios  from "axios";
import {Message} from 'element-ui';
// 使用自定义
const service = axios.create({
      baseURL:'https://b.yi-lian.net/api'
});
// 添加请求拦截器

service.interceptors.request.use( config=>{
    // 除过登录接口，其余接口都要携带token
    if(sessionStorage.getItem("mytoken")&&!config.headers['Authorization']){
      config.headers['Authorization']=sessionStorage.getItem("mytoken")
    }
    // 在发送请求之前做些什么
    return config;
      },  (error)=> {
        // 对请求错误做些什么
        return Promise.reject(error);
    });
// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
  }, function (error) {
    // 对响应错误做点什么
    let errMsg="";
  if (error && error.response&&error.response.status){
        switch(error.response.status){
            case 401:
                errMsg="登录状态失效，请重新登录";
                sessionStorage.removeItem("mytoken");
                break;
             case 403:
                errMsg="拒绝访问";
                break;
             case 408:
                errMsg="请求超时";
                break;
             case 500:
                errMsg="服务器内部错误";
                break;
              case 501:
                errMsg="服务未实现";
                break;
             case 502:
                errMsg="网关错误";
               break;
             case 503:
                errMsg="服务不可用";
               break;
             case 504:
                errMsg="网关超时";
               break;
           　case 505:
                errMsg="HTTP版本不受支持";
               break;
            default:
                errMsg = error.response.data.msg;
                break;
        }
    }else{
        errMsg = error;
    }
    Message.error(errMsg);
    return Promise.reject(error);
});

export default service;