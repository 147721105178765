import {
  GetParam
} from "../../utils/getParams";
import axios from "axios"
import {
  searchDoc
} from "../../api/index";
let config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
export default {
  data() {
    return {
      smallNavShow: false,
      headerlist: [{
        title: "首页",
        toUrl: "/"
      },
      // {
      //   title: "直播大厅",
      //   toUrl: "/liveHall"
      // },
      // {
      //   title: "我的直播",
      //   toUrl: "/liveChannel"
      // },
      ],
      navActive: 0,
      openId: "",
      headImgUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      nickName: "",
      username: "",
    }
  },
  created() {
    var ua = navigator.userAgent.toLowerCase();
    let phoneNum = sessionStorage.getItem("phone")
    if (sessionStorage.getItem("userinfo") || phoneNum) {
      if (phoneNum) {
        let databyph = {
          phone: phoneNum
        }
        this.getdocph(databyph)
      } else if (sessionStorage.getItem("userinfo")) {
        this.headImgUrl = JSON.parse(
          sessionStorage.getItem("userinfo")
        ).headImgUrl;
        this.nickName = JSON.parse(sessionStorage.getItem("userinfo")).nickName;
        this.openId = JSON.parse(sessionStorage.getItem("userinfo")).openId;
        this.username = JSON.parse(sessionStorage.getItem("userinfo")).name;
      }
    } else {
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        window.location.href = "http://s.yi-lian.net"

      } else {
        this.$router.push("/login")
      }
    }
  },
  mounted() {
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL);
    });
  },
  methods: {
    async getdocph(data) {
      const userinfo = await searchDoc(data)
      console.log("userinfo:", userinfo);
      if (userinfo.code === 200) {
        let userdata = {
          name: userinfo.data.records[0].name,
          nickName: userinfo.data.records[0].nickName,
          headImgUrl: userinfo.data.records[0].headImgUrl,
          phone: userinfo.data.records[0].phone,
          isRealName: userinfo.data.records[0].isRealName,
          agoraUid: userinfo.data.records[0].agoraUid,
        }
        sessionStorage.setItem("userinfo", JSON.stringify(userdata));
        this.headImgUrl = JSON.parse(
          sessionStorage.getItem("userinfo")
        ).headImgUrl;
        this.nickName = JSON.parse(sessionStorage.getItem("userinfo")).nickName;
        this.openId = JSON.parse(sessionStorage.getItem("userinfo")).openId;
        this.username = JSON.parse(sessionStorage.getItem("userinfo")).name;
      }
    },
    smallNavHandler() {
      this.smallNavShow = !this.smallNavShow
    },
    topage(url, ind) {
      this.navActive = ind
      this.smallNavHandler()
      this.$router.push(url)
    },
    loginout() {
      this.$confirm("是否退出登录?", "退出", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sessionStorage.removeItem("user");
          this.$router.replace("/login"); //$router.replace()--跳转页面，history不添加新纪录
          this.$message({
            showClose: true,
            type: "success",
            message: "退出成功!",
            duration: 1000,
          });
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "info",
            message: "已取消退出",
            duration: 1000,
          });
        });
    },
  }
}